<template>
  <div class="detail_wrap">
    <div class="mask_img">
      <div class="title_img">
        <img src="../../assets/image/about/about_title.png" alt="" />
      </div>
      <div class="title">关于我们</div>
    </div>
    <div v-if="false" class="detail_bg">
      <div class="lat">
        <img src="../../assets/image/about/about_lat.png" alt="" />
      </div>
      <div class="detail_img">
        <img src="../../assets/image/about/detail/detail_img.png" alt="" />
      </div>
    </div>

    <div class="detail_content">
      <div class="content_desc" v-if="list && list.length > 0">
        <div class="desc_item">
          <div class="item_title">
            <img src="../../assets/image/about/detail/1.png" alt="" />
          </div>
          <div class="item_desc">
            {{ list[0].content }}
          </div>
          <div class="item_line"></div>
        </div>
        <div class="desc_item">
          <div class="item_title">
            <img src="../../assets/image/about/detail/2.png" alt="" />
          </div>
          <div class="item_desc">
            {{ list[1].content }}
          </div>
          <div class="item_line"></div>
        </div>
        <div class="desc_item">
          <div class="item_title">
            <img src="../../assets/image/about/detail/3.png" alt="" />
          </div>
          <div class="item_desc">
            {{ list[2].content }}
          </div>
          <div class="item_line"></div>
        </div>
      </div>

      <!-- 活动 -->
      <div class="activity_wrap" v-if="activityList && activityList.length > 0">
        <div class="activity_list" ref="activity">
          <div class="activity_item" v-for="item in activityList" :key="item">
            <img :src="item.pictureUrl" alt="" />
            <div class="bottom_line"></div>
          </div>
        </div>
        <div class="right_arrow" @click="scrollRight">
          <img src="../../assets/image/about/detail/right_arrow.png" alt="" />
        </div>
        <div class="right_left" @click="scrollLeft">
          <img src="../../assets/image/about/detail/left_arrow.png" alt="" />
        </div>
      </div>

      <div class="team_wrap" v-for="(item, index) in teacherList" :key="index">
        <div class="team_title">{{ item.categoryName }}</div>
        <div class="team_list">
          <div
            class="team_item"
            v-for="(item1, i) in item.teacherList"
            :key="item1.id"
            @click="itemDetail(item1)"
            @mouseenter="mouseenter(item1, i, index)"
            @mouseleave="mouseenter(item1, i, index)"
          >
            <div class="item_img">
              <img v-if="!item1.hover" :src="item1.avatarUrl" alt="" />
              <img v-else :src="item1.effectsUrl" alt="" />
            </div>
            <div class="item_title">
              {{ item1.teacherName }} {{ item1.teacherNameEn }}
            </div>
          </div>
        </div>
      </div>

      <div class="compnay_list" v-if="compnayList && compnayList.length > 0">
        <div class="compnay_title">合作企业</div>
        <div class="compnay_img">
          <img :src="compnayList[0].pictureUrl" alt="" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getAboutUsList, getImageList, getTeacherList } from "@/apis/index";
export default {
  name: "aboutDetail",
  data() {
    return {
      count: 0,
      step: 0.8,
      list: [],
      activityList: [],
      compnayList: [],
      teacherList: [],
      isHover: false,
    };
  },
  created() {
    this.getList();
    this.queryForImages();
    this.queryForTeams();
  },
  methods: {
    mouseenter(item) {
      this.teacherList.forEach((element) => {
        element.teacherList.forEach((el) => {
          if (el.id === item.id) {
            el.hover = !el.hover;
          }
        });
      });
      console.log("list", this.teacherList);
    },
    itemDetail(item) {
      // console.log(item);
      this.$router.push({
        path: "/detail/teamDetail",
        query: {
          item: item,
        },
      });
    },
    getList() {
      getAboutUsList({ typeList: [2, 3, 4] }).then((res) => {
        if (res.code === 200) {
          this.list = res.data;
        }
        console.log(res);
      });
    },
    queryForImages() {
      getImageList({ picType: 5 }).then((res) => {
        console.log(res);
        if (res.code === 200) {
          this.activityList = res.data;
        }
      });
      getImageList({ picType: 4 }).then((res) => {
        console.log(res);
        if (res.code === 200) {
          this.compnayList = res.data;
        }
      });
    },
    queryForTeams() {
      getTeacherList().then((res) => {
        console.log(res);
        if (res.code === 200) {
          const array = res.data;
          array.forEach((element) => {
            element.teacherList.forEach((item) => {
              item.hover = false;
            });
          });
          this.teacherList = array;
        }
      });
    },
    scrollLeft() {
      var left = 0;
      const inter = setInterval(() => {
        var space = this.step * this.count;
        this.$refs.activity.scrollLeft -= space;
        left -= space;
        if (-968 > left) {
          this.count = 0;
          clearInterval(inter);
        }
        this.count++;
      }, 10);
    },
    scrollRight() {
      var left = 0;
      const inter = setInterval(() => {
        var space = this.step * this.count;
        this.$refs.activity.scrollLeft += space;
        left += space;
        if (968 - 67 - 109 < left) {
          this.count = 0;
          clearInterval(inter);
        }
        this.count++;
      }, 10);
    },
  },
};
</script>

<style>
</style>
<style lang="less" scoped>
@remvw:1920 / 100vw;
@supports (position: sticky) {
  .team_title {
    position: sticky;
    top: 415 / @remvw;
  }
}

@supports (position: sticky) {
  .compnay_title {
    position: sticky;
    top: 415 / @remvw;
  }
}
.detail_wrap {
  // position: relative;
  width: 1920px;
  // overflow: hidden;
  height: auto;
}
.detail_bg {
  position: absolute;
  width: 1760px;
  height: 927px;
  left: 50%;
  top: 145px;
  transform: translateX(-50%);
  background: url("../../assets/image/about/detail/bg.png") center center
    no-repeat;
  background-size: 100% 100%;
  background-color: #12151a;
  z-index: 99;

  .lat {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 0;
    width: 115px;
    height: 55px;
  }

  .detail_img {
    position: absolute;
    right: 105px;
    top: 37px;
    width: 653px;
    height: 632px;
  }
}

.mask_img {
  position: fixed;
  width: 1920px;
  height: 416px;
  top: 0px;
  z-index: 120;
  background: url("../../assets/image/about/detail/mask_img.png") center center
    no-repeat;
  background-size: 100% 100%;

  .title_img {
    width: 748px;
    height: 64px;
    margin-left: 111px;
    margin-top: 180px;
  }

  .title {
    padding-left: 111px;
    padding-top: 37px;
    font-size: 40px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #ffffff;
    line-height: 40px;
    letter-spacing: 16px;
  }
}

.detail_content {
  // position: relative;
  padding-top: 280px;
  z-index: 110;

  .content_desc {
    margin: auto;
    width: 1700px;
    height: 651px;
    background: url("../../assets/image/about/detail/desc_bg.png") center center
      no-repeat;
    background-size: 100% 100%;

    .desc_item {
      width: 1307px;
      padding-left: 48px;
      padding-top: 65px;

      .item_title {
        width: auto;
        height: 24px;

        img {
          width: auto;
        }
      }

      .item_desc {
        padding-top: 21px;
        padding-bottom: 21px;
        font-size: 21px;
        font-family: PingFangSC-Light, PingFang SC;
        font-weight: 300;
        color: #ffffff;
        line-height: 37px;
      }

      .item_line {
        width: 342px;
        // height: 1px;
        border: 1px solid;
        border-image: linear-gradient(
            270deg,
            rgba(190, 255, 250, 0.21),
            rgba(138, 255, 242, 1)
          )
          1 1;
        border-radius: 50%;
      }

      &:first-child {
        padding-top: 47px;
      }
    }
  }

  .activity_wrap {
    width: 100%;
    height: 651px;
    padding-top: 133px;
    padding-bottom: 26px;
    overflow: hidden;
    position: relative;

    .right_arrow {
      position: absolute;
      right: 69px;
      top: 50%;
      transform: translateY(-50%);
      width: 64px;
      height: 64px;
    }

    .right_left {
      position: absolute;
      left: 69px;
      top: 50%;
      transform: translateY(-50%);
      width: 64px;
      height: 64px;
    }

    .activity_list {
      padding-left: 109px;
      overflow-x: auto;
      display: flex;
      justify-content: flex-start;

      .activity_item {
        flex-shrink: 0;
        width: 968px;
        height: 545px;
        // border-top-left-radius: 19px;
        // border-top-right-radius: 19px;
        margin-right: 67px;
        background-color: #8afff2;
        border: 1px solid #979797;
        overflow: hidden;
        position: relative;

        img {
          transition: all 0.4s ease 0s;
        }

        &:last-child {
          margin-right: 0;
        }

        .bottom_line {
          position: absolute;
          bottom: 0;
          left: 0;
          width: 100%;
          height: 3px;
          background-color: #8afff2;
          transition: all 0.4s ease 0s;
        }

        &:hover img {
          transform: scale(1.05);
          transition: all 0.4s ease 0s;
        }

        &:hover .bottom_line {
          height: 16px;
          transition: all 0.4s ease 0s;
        }
      }
    }
  }

  .team_wrap {
    margin: auto;
    width: 1700px;
    margin-top: 107px;
    margin-bottom: -107px;

    .team_title {
      width: 100%;
      height: 60px;
      font-size: 40px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #ffffff;
      // line-height: 40px;
      margin-bottom: 67px;
      background-color: #12151a;
    }

    .team_list {
      z-index: 999;
      display: flex;
      justify-content: flex-start;
      flex-wrap: wrap;

      .team_item {
        width: 316px;
        height: 396px;
        // background-color: rebeccapurple;
        margin-right: 145px;
        margin-bottom: 107px;

        &:nth-child(4n) {
          margin-right: 0px;
        }

        .item_img {
          width: 316px;
          height: 316px;
        }

        .item_title {
          padding-top: 30px;
          font-size: 32px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #ffffff;
          line-height: 40px;
          white-space: nowrap;
        }
      }
    }
  }

  .compnay_list {
    margin: auto;
    width: 1700px;
    margin-top: 128px;

    .compnay_title {
      width: 100%;
      height: 60px;
      margin-bottom: 40px;
      font-size: 40px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #ffffff;
      // line-height: 40px;
      background-color: #12151a;
    }

    .compnay_img {
      width: 100%;
      height: 563px;
    }
  }
}
</style>